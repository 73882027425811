import React, { useState, useCallback, useEffect } from "react";
import * as t from "../../data/types";
import { useTeacherLoggedIn } from "../../context/UserContext";
import { createUniqueID, handleError, spaceBeforeCapitals } from "../../utils/sharedUtils";
import { convertDateToString, getTimeFromDate, parseDate } from "../../utils/dates";
import createNewExamAPI from "../../services/api/createNewExamAPI";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import { ArrowDown, ArrowUp } from "lucide-react";
import { useAlert } from "../../context/AlertContext";
import SelectTemplate from "./createNewExam/SelectTemplate";
import { checkValidInput, getMovementDescription } from "./createNewExam/utils";
import CreateQuestionModal from "./createNewExam/CreateQuestionModal";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../../data/constants/constants";

const flatpickrTimeOptions = {
	enableTime: true,
	noCalendar: true,
	dateFormat: "H:i",
	time_24hr: true,
};

const flatpickrDateOptions = {
	dateFormat: "d-m-Y",
	minDate: new Date(),
};


export default function CreateNewExam({
	selectedClass,
	selectedExam,
	setSelectedExam,
}: {
	selectedClass: any;
	selectedExam: t.Exam | undefined;
	setSelectedExam: React.Dispatch<React.SetStateAction<t.Exam | undefined>>;
}): JSX.Element {
	const { teacher, setClassData, setLoading, clickOutside } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const { t } = useTranslation("Exams");
	const [showModal, setShowModal] = useState(false);
	const [showSelectTemplate, setShowSelectTemplate] = useState(false);
	const [examName, setExamName] = useState("");
	const [startDate, setStartDate] = useState<Date | undefined>(undefined);
	const [endDate, setEndDate] = useState<Date | undefined>(undefined);
	const [startTime, setStartTime] = useState<string | undefined>(undefined);
	const [endTime, setEndTime] = useState<string | undefined>(undefined);
	const [examContent, setExamContent] = useState<t.ExamContent[]>([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [questionToEdit, setQuestionToEdit] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!selectedExam) return;
		if (selectedExam.Name) setExamName(selectedExam.Name);
		if (selectedExam.StartTime) setStartDate(new Date(selectedExam.StartTime));
		if (selectedExam.StartTime) setStartTime(getTimeFromDate(selectedExam.StartTime));
		if (selectedExam.EndTime) setEndTime(getTimeFromDate(selectedExam.EndTime));
		if (selectedExam.Content) setExamContent(selectedExam.Content);
	}, [selectedExam]);


	useEffect(() => {
		setShowModal(false);
	}, [clickOutside]);

	useEffect(() => {
		if (startTime === undefined) return;
		if (startTime !== undefined && endTime !== undefined && endTime > startTime) return;
		const timeDate = parseDate("1989-07-10T" + startTime);
		timeDate.setHours(timeDate.getHours() + 1);
		setEndTime(getTimeFromDate(timeDate));
	}, [startTime]);

	const handleStartDateSelect = (selectedDates: Date[]) => {
		const selectedDate = selectedDates[0];
		if (selectedDate) {
			setStartDate(selectedDate);

			// If endDate is not set or is before the new startDate, update endDate
			if (!endDate || selectedDate > endDate) setEndDate(selectedDate);
		}
	};

	const handleEndDateSelect = (selectedDates: Date[]) => {
		const selectedDate = selectedDates[0];
		if (selectedDate) {
			setEndDate(selectedDate);

			// If startDate is not set or is after the new endDate, update startDate
			if (!startDate || selectedDate < startDate) setStartDate(selectedDate);
		}
	};

	const handleCreation = useCallback(async () => {
		setErrorMessage("");

		if (!checkValidInput(examName, startDate, endDate, startTime, endTime, examContent, setErrorMessage, t)) return;
		setLoading(true);
		const today = new Date();
		const examData: t.Exam = {
			id: selectedExam ? selectedExam.id : createUniqueID(),
			ClassID: selectedClass.id,
			Name: examName,
			StartTime: startDate ? startDate : today,
			EndTime: endDate ? endDate : today,
			Content: examContent,
			Results: [],
		};
		try {
			await createNewExamAPI(examData);
			await getClassesByTeacherAPI(teacher.id, setClassData);
			triggerAlert(`${t("examSuccessfullyCreated1")}${examName}${t("examSuccessfullyCreated2")}`, "success");
		} catch (error) {
			triggerAlert(handleError(error).userMessage, "error");
			if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
		} finally {
			setSelectedExam(undefined);
			setLoading(false);
		}
	}, [
		examName,
		startDate,
		endDate,
		startTime,
		endTime,
		examContent,
		selectedClass,
		teacher,
		setClassData,
		setLoading,
		triggerAlert,
		t,
	]);


	return (
		<div className="basicCard createNewContent">
			<label>
				<h3>{t("nameOfExam")}</h3>
				<input
					className="inputField"
					type="text"
					value={examName}
					onChange={(e) => setExamName(e.target.value)}
					required
				/>
			</label>
			<div className="dateTimePicker">
				<label className="datePicker">
					<h3>{t("startDate")}</h3>
					<Flatpickr
						options={flatpickrDateOptions}
						value={startDate || undefined}
						onChange={handleStartDateSelect}
						placeholder="dd / mm / yyyy"
					/>
				</label>
				<label className="timePicker">
					<h3>{t("startTime")}</h3>
					<Flatpickr
						value={startTime || undefined}
						onChange={(selectedDates: Date[]) => {
							if (selectedDates[0]) setStartTime(getTimeFromDate(selectedDates[0]));
						}}
						options={flatpickrTimeOptions}
						placeholder="hh:mm"
					/>
				</label>
				<label className="datePicker">
					<h3>{t("endDate")}</h3>
					<Flatpickr
						options={flatpickrDateOptions}
						value={endDate || undefined}
						onChange={handleEndDateSelect}
						placeholder="dd / mm / yyyy"
					/>
				</label>
				<label className="timePicker">
					<h3>{t("endTime")}</h3>
					<Flatpickr
						value={endTime || undefined}
						onChange={(selectedDates: Date[]) => {
							if (selectedDates[0]) setEndTime(getTimeFromDate(selectedDates[0]));
						}}
						options={flatpickrTimeOptions}
						placeholder="hh:mm"
					/>
				</label>
			</div>
			<div className="overviewTable">
				<ExamContent
					examContent={examContent}
					setExamContent={setExamContent}
					setShowModal={setShowModal}
					setQuestionToEdit={setQuestionToEdit}
				/>
				<AddMoreQuestions
					examLength={examContent.length}
					setShowModal={setShowModal}
					setShowSelectTemplate={setShowSelectTemplate}
				/>
			</div>

			<div>
				{errorMessage && <p className="error">{errorMessage}</p>}
				<button type="submit" onClick={handleCreation}>
					{selectedExam ? t("saveEditsToExam") : t("createNewExam")}
				</button>
			</div>
			<CreateQuestionModal
				examContent={examContent}
				setExamContent={setExamContent}
				questionToEdit={questionToEdit}
				setQuestionToEdit={setQuestionToEdit}
				showModal={showModal}
				onClose={() => setShowModal(false)}
			/>
			<SelectTemplate
				currentSelectedClass={selectedClass}
				showModal={showSelectTemplate}
				setExamContent={setExamContent}
				onClose={() => setShowSelectTemplate(false)}
			/>
		</div>
	);
}
function ExamContent({
	examContent,
	setExamContent,
	setShowModal,
	setQuestionToEdit,
}: {
	examContent: t.ExamContent[];
	setExamContent: any;
	setShowModal: any;
	setQuestionToEdit: any;
}) {
	const { t } = useTranslation("Exams");

	const moveQuestion = (index: number, direction: "up" | "down") => {
		const updatedExamContent = [...examContent];
		if (index == 0 && direction === "up") return;
		if (index == updatedExamContent.length - 1 && direction === "down") return;
		const newIndex = direction === "up" ? index - 1 : index + 1;
		[updatedExamContent[index], updatedExamContent[newIndex]] = [
			updatedExamContent[newIndex],
			updatedExamContent[index],
		];
		setExamContent(updatedExamContent);
	};

	const handleEditQuestion = (index: number) => {
		return () => {
			setQuestionToEdit(index);
			setShowModal(true);
		};
	};

	const handleRemoveQuestion = (index: number) => {
		return () => {
			const updatedExamContent = [...examContent];
			updatedExamContent.splice(index, 1);
			setExamContent(updatedExamContent);
		};
	};

	if (!examContent) return null;
	return (
		<>
			{examContent.length > 0 && (
				<table>
					<thead className="theadCSS">
						<tr>
							<th>{t("position")}</th>
							<th>{t("module")}</th>
							<th>{t("mode")}</th>
							<th>{t("movement")}</th>
							<th>{t("root")}</th>
							<th>{t("options")}</th>
							<th>{t("questions")}</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{examContent.map((question, index) => (
							<tr key={index}>
								<td className="link">
									<ArrowUp
										className={index === 0 ? "disabled" : ""}
										onClick={() => moveQuestion(index, "up")}
									/>
									<ArrowDown
										className={index === examContent.length - 1 ? "disabled" : ""}
										onClick={() => moveQuestion(index, "down")}
									/>
								</td>
								<td>{spaceBeforeCapitals(question.Module)}</td>
								<td>{spaceBeforeCapitals(question.Difficulty)}</td>
								<td>{getMovementDescription(question.Module, question.Movement)}</td>
								<td>{question.Dynamic}</td>
								<td className="alignRight">{question.Options?.length}</td>
								<td className="alignRight">{question.NumberOfQuestions}</td>
								<td className="alignRight link" onClick={handleEditQuestion(index)}>
									{t("edit")}
								</td>
								<td className="error link" onClick={handleRemoveQuestion(index)}>
									{t("delete")}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</>
	);
}

function AddMoreQuestions({
	examLength,
	setShowSelectTemplate,
	setShowModal,
}: {
	examLength: number;
	setShowSelectTemplate: any;
	setShowModal: any;
}) {
	const { t } = useTranslation("Exams");

	return (
		<>
			{examLength === 0 ? (
				<>
					<button className="buttonOpen" onClick={() => setShowSelectTemplate(true)}>
						{t("usePreviousExamAsTemplate")}
					</button>
					<br />
					<br />
					<button className="buttonOpen" onClick={() => setShowModal(true)}>
						{t("addQuestion")}
					</button>
				</>
			) : (
				<>
					<br />
					<button className="buttonOpen" onClick={() => setShowModal(true)}>
						{t("addMoreQuestions")}
					</button>
				</>
			)}
			<br />
		</>
	);
}
